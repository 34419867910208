// Copyright (C) 2024 by Posit Software, PBC.

import RSSParser from 'rss-parser';

const url = 'https://posit-dev.github.io/connect-news-feed/index.xml';
const parser = new RSSParser({
  customFields: {
    item: ['thumbnail', 'themes', 'products','minimumConnectVersion','connectUserRoles'],
  }
});

export async function getConnectFeed() {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('HTTP Error');
    } else {
      const text = await response.text();
      return parseFeedString(text);
    }
  } catch (error) {
    throw new Error(`Fetch error: ${error.response}`);
  }
};

export const parseFeedString = (data) => {
  return new Promise((resolve, reject) => {
    parser.parseString(data, (err, parsed) => {
      if (err) {
        reject(new Error(`Parsing error: ${err.response}`));
      } else {
        resolve(parsed.items);
      }
    });
  });
};
