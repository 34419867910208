<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="content-actions actionBar">
    <RefreshButton
      v-show="show.refreshReport"
    />
    <OpenSoloButton
      v-show="show.openSolo"
      :dropdown="false"
    />
    <EmailReportButton
      v-show="show.emailReport"
    />
    <LogsButton
      v-show="show.logs"
      :dropdown="false"
    />

    <div class="popupButtonAndMenuContainer content-actions__more-container">
      <button
        aria-label="More Options"
        title="More Options"
        class="action moreActions menuButton content-actions__more-button"
        :class="{ current: isMoreOpen }"
        data-automation="menuitem-more"
        @click.stop.prevent="isMoreOpen = !isMoreOpen"
      />

      <div
        class="popupMenu content-actions__more-menu"
        :class="{ open: isMoreOpen }"
        data-automation="menuitem-popup-container"
      >
        <OpenSoloButton
          v-show="show.openSolo"
          :dropdown="true"
        />
        <PrintButton
          v-show="show.print"
          :dropdown="true"
        />
        <LogsButton
          v-show="show.logs"
          :dropdown="true"
        />
        <HistoryButton
          v-show="show.history"
        />
        <SourceVersionsButton
          v-show="show.sourceVersions"
          @source-versions="sourceVersionsActive = true"
        />
        <DeleteButton
          v-show="show.delete"
          @delete-modal="deleteModalActive = true"
        />
      </div>
    </div>

    <SettingsButton v-show="show.settings" />
    <DeleteModal
      v-if="deleteModalActive"
      @delete-modal="deleteModalActive = false"
    />
    <SourceVersions
      v-if="sourceVersionsActive"
      @source-versions="sourceVersionsActive = false"
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapState } from 'vuex';
import DeleteButton from './DeleteButton';
import DeleteModal from './DeleteModal';
import EmailReportButton from './EmailReportButton.vue';
import HistoryButton from './HistoryButton';
import LogsButton from './LogsButton';
import OpenSoloButton from './OpenSoloButton';
import PrintButton from './PrintButton';
import RefreshButton from './RefreshButton';
import SettingsButton from './SettingsButton';
import SourceVersions from './SourceVersions';
import SourceVersionsButton from './SourceVersionsButton';

export default {
  name: 'ContentActions',
  components: {
    RefreshButton,
    OpenSoloButton,
    EmailReportButton,
    LogsButton,
    SettingsButton,
    PrintButton,
    HistoryButton,
    SourceVersionsButton,
    SourceVersions,
    DeleteButton,
    DeleteModal,
  },
  data() {
    return {
      isMoreOpen: false,
      deleteModalActive: false,
      sourceVersionsActive: false,
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      hasAppError: state => state.contentView.appError,
      showParamsPanel: state => state.contentView.showParametersPanel,
      currentUser: state => state.currentUser.user,
      hasVariantError: state => state.parameterization.currentVariant?.error,
      variant: state => state.parameterization.currentVariant,
      paramsLoaded: state => state.legacyParams.form.loaded,
      dirtyParams: state => state.legacyParams.form.dirty,
      isVariantBusy: state => state.legacyParams.isBusy,
    }),
    isLoadingParamsPanel() {
      return this.showParamsPanel && !this.paramsLoaded;
    },
    showRefreshReport() {
      if (isEmpty(this.variant) || this.hasVariantError) {
        return false;
      }

      // Do not show:
      // - if there are changes in params or variant is busy
      // - params panel is loading
      // - app is locked
      if (this.dirtyParams || this.isVariantBusy || this.isLoadingParamsPanel || this.app.locked) {
        return false;
      }

      // must be an app editor, must be a report with a variant (because then you also have read
      // access), and must be deployed.
      return this.app.isDeployed() && this.currentUser.isAppEditor(this.app);
    },
    showOpenSolo() {
      if (this.hasAppError || !this.app.isDeployed()) {
        return false; // no app in state, load error, or not deployed, no solo.
      }

      if (!isEmpty(this.variant) && !this.variant.renderDuration) {
        return false; // no rendering; no solo.
      }

      return true;
    },
    showEmailReport() {
      if (this.hasAppError || !this.app.isDeployed() || this.app.locked) {
        return false; // load error or not deployed or locked
      }

      if (!this.currentUser.canViewApp(this.app)) {
        return false; // not permitted to view, no email.
      }

      if (!isEmpty(this.variant)) {
        if (this.isVariantBusy) {
          return false; // in-flight parameter mods, no email.
        }

        if (!this.variant.renderDuration) {
          return false; // never rendered, no email.
        }

        if (this.app.isSite()) {
          return false; // multi-page sites, no email.
        }

        if (this.isLoadingParamsPanel) {
          return false; // params panel is open and loading
        }

        return true;
      }

      // non-variant content, no email.
      return false;
    },
    showLogs() {
      return this.currentUser.canViewAppSettings(this.app);
    },
    showPrint() {
      if (this.hasAppError || !this.app.isDeployed() || this.app.locked) {
        return false;
      }

      if (!this.currentUser.canViewApp(this.app)) {
        return false;
      }

      if (!isEmpty(this.variant)) {
        if (this.isVariantBusy) {
          return false;
        }

        if (!this.variant.renderDuration) {
          return false;
        }

        if (this.app.isSite()) {
          return false;
        }
      }

      return true;
    },
    showHistory() {
      if (this.hasAppError || this.hasVariantError || this.app.locked) {
        return false;
      }
      return this.currentUser.canViewApp(this.app) &&
        (this.app.isStatic() || this.app.isRenderable());
    },
    showSourceVersions() {
      return !this.hasAppError && this.currentUser.isAppEditor(this.app);
    },
    showDelete() {
      return !this.hasAppError && this.currentUser.canDeleteApp(this.app);
    },
    showSettings() {
      return !this.hasAppError;
    },
    show() {
      return {
        refreshReport: this.showRefreshReport,
        openSolo: this.showOpenSolo,
        emailReport: this.showEmailReport,
        logs: this.showLogs,
        print: this.showPrint,
        history: this.showHistory,
        sourceVersions: this.showSourceVersions,
        delete: this.showDelete,
        settings: this.showSettings,
      };
    }
  },
  mounted() {
    document.addEventListener('click', this.closeMore);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeMore);
  },
  methods: {
    closeMore(e) {
      if (this.isMoreOpen) {
        this.isMoreOpen = false;
        e.stopPropagation();
        e.preventDefault();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.popupButtonAndMenuContainer {
  display: inline-block;
  position: relative;
}

.content-actions__more-container:has(.content-actions__more-menu:empty) {
  display: none;
}

.content-actions__more-menu {
  button:focus-visible {
    outline: none;
    box-shadow:0px 0px 0px 2px $color-posit-teal inset;
  }
}
</style>
