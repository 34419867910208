<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { reactive, computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { isEmpty } from 'lodash';
import UserRoles from '@/api/dto/userRole';
import { getConnectFeed } from '@/api/connectFeed.js';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import WhatsNewArticle from '@/components/WhatsNew/WhatsNewArticle.vue';
import WhatsNewEmptyState from '@/components/WhatsNew/WhatsNewEmptyState.vue';
import Spinner from '@/components/Spinner.vue';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

const localTime = dayjs.utc();
const store = useStore();
const currentUserRole = computed(() => UserRoles.stringOf(store.state.currentUser.user.userRole));

const localState = reactive({
  feed: [],
  loading: true,
  hasFeedError: false,
});

const filterFeed = (items) => items
  // Filters out items with a future date
  .filter((item) => dayjs(item.pubDate).isSameOrBefore(localTime))
  // Filters by current user's role
  .filter((item) => item.connectUserRoles.role
    .some((r) => r === currentUserRole.value));

const fetchConnectFeed = async() => {
  try {
    const data = await getConnectFeed();
    if (isEmpty(data)) {
      localState.hasFeedError = true;
      return;
    }

    localState.feed = filterFeed(data);
    localState.loading = false;
  } catch (err) {
    localState.loading = false;
    localState.hasFeedError = true;
    store.commit(SET_ERROR_MESSAGE_FROM_API, err);
  }
};

onBeforeMount(() => {
  fetchConnectFeed();
});
</script>

<template>
  <div class="band rsc-content">
    <div class="whats-new-content mainPage">
      <div 
        v-if="localState.loading"
        data-automation="whats-new-loading"
      >
        <Spinner />
      </div>
      <WhatsNewEmptyState
        v-if="localState.hasFeedError"
        data-automation="whats-new-empty"
      />
      <section
        v-if="!localState.hasFeedError && !localState.loading"
        id="whats-new"
        class="whats-new-section"
        data-automation="whats-new"
      >
        <h1 class="whats-new-title">
          <img
            src="/images/elements/whatsNew.svg"
            role="presentation"
            alt=""
            class="whats-new-icon"
          >
          What's new
        </h1>
        <WhatsNewArticle
          v-for="article in localState.feed"
          :key="article.guid"
          :article="article"
          data-automation="whats-new-article-list"
        />
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.whats-new-content {
  padding-top: 104px;
  padding-bottom: 175px;
}

.whats-new-title {
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 22px;
  margin-bottom: 24px;
}

.whats-new-icon {
  height: 20px;
  vertical-align: middle;
  width: 20px;
}
</style>
