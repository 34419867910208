<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <h2 class="menuSectionTitle">
      <RouterLink
        :to="{ name: name }"
        :data-automation="`left-nav-item ${dataAutomation}`"
      >
        {{ title }}
      </RouterLink>
    </h2>

    <ul
      v-if="children.length > 0"
      class="menuItems"
    >
      <li
        v-for="item in children"
        :key="item.title"
      >
        <a
          v-if="item.href"
          :href="item.href"
          :class="`menuItem ${item.class}`"
          :data-automation="`left-nav-item ${item.dataAutomation}`"
          :target="item.target"
        >
          {{ item.title }}
        </a>
        <RouterLink
          v-if="item.name"
          :to="{ name: item.name }"
          :class="`menuItem ${item.class} ${isActive(item.name)}`"
          :data-automation="`left-nav-item ${item.dataAutomation}`"
        >
          {{ item.title }}
        </RouterLink>
      </li>
    </ul>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  name: 'NavPanelMenu',
  components: {
    RouterLink
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    dataAutomation: {
      type: String,
      required: true,
    },
    children: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isActive(routeName) {
      if (this.$route.name?.match(routeName)) {
        return 'active';
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.menuSectionTitle {
  color: $color-primary;
  font-size: 13px;
  font-weight: 400;
  display: block;
  line-height: 30px;
  padding: 0 $side-panel-padding;

  a {
    display: block;
    padding: 0;

    &:focus {
      outline: none;
    }
    &:hover {
      text-decoration: none;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover, &:focus-visible {
    background-color: $color-posit-teal;
    font-weight: 600;
    a {
      color: $color-white;
    }
  }

  &:has(:focus-visible) {
    background-color: $color-posit-teal;
    color: $color-white;
    font-weight: 600;
  }
}

.menuItems {
  li {
    margin: 0;
    .menuItem {
      color: inherit;
      cursor: pointer;
      display: block;
      border-radius: 0;
      margin-right: 0;
      line-height: 40px;
      padding: 0 $side-panel-padding 0 47px;
      font-size: 13px;
      background-repeat: no-repeat;
      background-size: 15px 80px;
      background-position: 25px 0;

      &:focus {
        outline: none;
      }

      &:hover, &:focus-visible {
        text-decoration: none;
        background-color: $color-posit-teal;
        color: $color-white;
        font-weight: 600;
      }

        &:has(:focus-visible) {
        text-decoration: none;
        background-color: $color-posit-teal;
        color: $color-white;
        font-weight: 600;
      }

      &.active {
        background-position-y: -40px;
        background-color: $color-alternate;
      }

      &.users {
        background-image: url(/images/elements/navUsers.svg);
      }
      &.groups {
        background-image: url(/images/elements/navGroups.svg);
      }
      &.metrics {
        background-image: url(/images/elements/navMetrics.svg);
      }
      &.settings {
        background-image: url(/images/elements/navSettings.svg);
      }
      &.tags {
        background-image: url(/images/elements/navTags.svg);
      }
      &.auditLogs {
        background-image: url(/images/elements/navAuditLogs.svg);
      }
      &.scheduled {
        background-image: url(/images/elements/navScheduled.svg);
      }
      &.processes {
        background-image: url(/images/elements/navProcesses.svg);
      }
      &.systemChecks {
        background-image: url(/images/elements/navSystemChecks.svg);
      }
      &.queue {
        background-image: url(/images/elements/navQueue.svg);
      }
      &.whatsNew {
        background-image: url(/images/elements/navWhatsNew.svg);
      }
      &.userGuide {
        background-image: url(/images/elements/navUserGuide.svg);
      }
      &.adminGuide {
        background-image: url(/images/elements/navAdminGuide.svg);
      }
      &.faq {
        background-image: url(/images/elements/navFAQ.svg);
      }
      &.articles {
        background-image: url(/images/elements/navArticles.svg);
      }
      &.releaseNotes {
        background-image: url(/images/elements/navReleaseNotes.svg);
      }
      &.apiDocs {
        background-image: url(/images/elements/navApiDocs.svg);
      }
    }
  }
}
</style>
