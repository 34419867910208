<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <ul
    v-if="applications.length"
    data-automation="content-list__blog"
  >
    <li
      v-for="app in applications"
      :key="app.guid"
      class="listItem content-blog-item"
    >
      <RouterLink
        :to="{ name: 'apps', params: { idOrGuid: app.guid } }"
        :title="tooltipFor(app)"
        class="listingImageWrapper"
        tabindex="-1"
      >
        <img
          class="content-blog-item__image"
          :alt="app.displayName"
          :src="contentThumbnailPath(app.guid)"
          data-automation="blog-item-image"
          @error="evt => contentThumbnailFallback(evt, app)"
        >
      </RouterLink>
      <div class="itemDetails">
        <div class="itemHeader">
          <RouterLink
            :to="{ name: 'apps', params: { idOrGuid: app.guid } }"
            :title="tooltipFor(app)"
            class="itemTitle content-blog-item__title"
            data-automation="blog-item-title"
          >
            <span
              :class="{ 'rsc-content-list__unpublished': !app.isDeployed() }"
            >
              {{ app.contentDisplayName() || 'Untitled' }}
            </span>
          </RouterLink>
        </div>

        <div class="itemMeta">
          <div
            v-if="hasOwner(app)"
            class="author"
            data-automation="blog-owner"
          >
            <RouterLink
              :to="{ name: 'people.users.profile', params: { guid: app.ownerGuid } }"
              title="Go to content owner's profile"
            >
              <RSPrincipalInfo
                :initials="displayOwnerInitials(app)"
                :name="ownerName(app)"
              />
            </RouterLink>
          </div>
          <div
            class="itemDescription"
            data-automation="blog-description"
          >
            <span>{{ app.description }}</span>
          </div>
          <div class="itemInfo">
            <div
              class="content-blog-item__info-chunk"
              data-automation="blog-type"
            >
              Type: {{ descriptionFor(app) }}
            </div>
            <div
              class="content-blog-item__info-chunk"
              data-automation="blog-deployed"
            >
              <span v-if="app.isDeployed()">
                Last Deployed: {{ lastDeployedTime(app) }}{{ fromGit(app.git) }}
              </span>
              <span
                v-else
                class="rsc-content-list__unpublished"
              >
                unpublished
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import RSPrincipalInfo from '@/elements/RSPrincipalInfo';
import { activeTime } from '@/utils/activeTime.filter';
import {
  contentTooltips,
  displayOwnerInitials,
  displayOwnerName,
  fromGit,
  hasOwner,
} from '@/utils/contentList';
import { serverPath } from '@/utils/paths';
import { truncate } from '@/utils/truncate.filter';
import { contentTypeLabel } from './contentTypes';
import { RouterLink } from 'vue-router';

export default {
  name: 'ContentBlog',
  components: {
    RSPrincipalInfo,
    RouterLink,
  },
  props: {
    applications: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    hasOwner,
    fromGit,
    displayOwnerInitials,
    contentThumbnailPath(contentGuid) {
      return serverPath(`content/${contentGuid}/__thumbnail__`);
    },
    contentThumbnailFallback(evt, app) {
      const imgType = app.thumbnailType();
      evt.target.src = `images/contenttype/${imgType}.svg`;
      evt.target.classList.add('content-blog-item__image--default');
    },
    ownerName(app) {
      // TODO: word-wrap the displayOwnerName rather than truncate it,
      // once word-wrapping is fixed for RSPrincipalInfo component
      const ownerName = displayOwnerName(app);
      return truncate(ownerName, 18);
    },
    lastDeployedTime(app) {
      return activeTime(app.lastDeployedTime);
    },
    descriptionFor(app) {
      return contentTypeLabel(app.appMode);
    },
    tooltipFor: app => contentTooltips[app.contentType()],
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.listItem {
  @include flex-space-after();
  padding-top: 6px;
  padding-bottom: 20px;
  position: relative;

  .listingImageWrapper {
    display: block;
    flex-shrink: 0;

    width: 150px;
    margin: 0 15px 15px 0;
    height: fit-content;

    img {
      width: 150px;

      &:after {
        content: " ";
        display: block;
        background-color: $color-light-grey;
        width: 150px;
        height: 150px;
      }
    }
  }

  .itemDetails {
    width: 100%;
    font-size: 13px;
    line-height: 20px;

    .itemHeader {
      @include flex-space-between();
      align-items: flex-start;
      line-height: 30px;
      margin-bottom: 3px;

      .itemTitle {
        font-size: 20px;
        display: block;
      }
    }

    .itemMeta {
      .itemDescription {
        margin-bottom: 5px;
        white-space: pre-line;
      }

      .itemDate, .itemInfo {
        font-size: 12px;
        color: $color-dark-grey;
      }
    }
  }
}

@media screen and (max-width: 500px){
  .listItem {
    display: block;
    clear: both;

    &:after {
      @include really-clear-float();
    }
  }
}
.author {
  display: flex;
}
</style>
