<!-- Copyright (C) 2024 by Posit Software, PBC. -->
<template>
  <section
    class="whats-new-empty"
  >
    <div>
      <p class="emphasize">
        Oops, we can't load the feed.
      </p>
      <p>
        See the latest developments on Posit Connect at
        <a
          target="_blank"
          href="https://posit-dev.github.io/connect-news-feed/"
        >https://posit-dev.github.io/connect-news-feed/</a>.
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.whats-new-empty {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  text-align: center;
  padding: 10rem 0 4rem 0;

  p {
    margin-bottom: 1.375rem;

    &:not(.emphasize) {
      color: $color-dark-grey;
    }
  }

  a {
    text-decoration: underline;
  }
}
</style>
