<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div class="band">
    <AboutLocal
      v-if="initialized && !hasOffHostEnv"
      :build="build"
      :version="version"
      :end-of-support="endOfSupport"
      :environments="environments"
      :user-profile="userProfile"
    />
    <AboutOffHost
      v-if="initialized && hasOffHostEnv"
      :build="build"
      :version="version"
      :end-of-support="endOfSupport"
      :environments="environments"
      :user-profile="userProfile"
    />
  </div>
</template>

<script>
import { normalizedVersion, endOfSupport } from '@/utils/version';
import {
  getServerSettings,
  getRInstallations,
  getPythonInstallations,
  getQuartoInstallations,
  getTensorFlowInstallations,
  ExecutionTypeK8S,
} from '@/api/serverSettings';
import { mapState, mapMutations } from 'vuex';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import AboutLocal from '@/views/documentation/AboutLocal';
import AboutOffHost from '@/views/documentation/AboutOffHost';
import UserRoles from '@/api/dto/userRole';

dayjs.extend(localizedFormat);

export default {
  name: 'About',
  components: {
    AboutLocal,
    AboutOffHost,
  },
  data() {
    return {
      about: '',
      build: '',
      version: '',
      endOfSupport: '',
      userProfile: {},
      rInstalls: [],
      pythonInstalls: [],
      quartoInstalls: [],
      tensorflowInstalls: [],
      environments: {},
      initialized: false,
      hasOffHostEnv: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    async initialize() {
      try {
        const serverSettings = await getServerSettings();
        const { about, build, version, executionType } = serverSettings;
        this.about = about;
        this.build = build;
        this.version = version;
        if (version !== '') {
          const normalized = normalizedVersion(version);
          const eos = endOfSupport(normalized);
          const formatted = eos.format('LL');
          this.endOfSupport = formatted;
        }

        this.hasOffHostEnv = executionType === ExecutionTypeK8S; // only if k8s

        if (this.currentUser?.userRole >= UserRoles.Publisher && !this.hasOffHostEnv) {
          await this.getInstallations();
        }
        this.initialized = true;
      } catch (e) {
        this.setErrorMessageFromAPI(e);
      }
    },
    // This will update this.environments
    // versionType: 'rVersions', 'pythonVersions', 'quartoVersions', or 'tensorflowVersions'
    combineVersionsWithInstalls(installs, versionType) {
      if (versionType !== 'rVersions' && versionType !== 'pythonVersions' && versionType !== 'quartoVersions' && versionType !== 'tensorflowVersions') {
        return;
      }
      installs.forEach(install => {
        const key = `${install.cluster_name}:${install.image_name}`;
        if (this.environments[key] === undefined) {
          this.environments[key] = {
            clusterName: install.cluster_name,
            imageName: install.image_name,
            rVersions: [],
            pythonVersions: [],
            quartoVersions: [],
            tensorflowVersions: [],
          };
        }
        // Duplicates may come through, so we need to suppress them
        if (!this.environments[key][versionType].includes(install.version)) {
          this.environments[key][versionType].push(install.version);
        }
      });
    },
    async getInstallations() {
      try {
        this.environments = {};

        const rInstalls = await getRInstallations();
        this.combineVersionsWithInstalls(rInstalls, 'rVersions');

        const pythonInstalls = await getPythonInstallations();
        this.combineVersionsWithInstalls(pythonInstalls, 'pythonVersions');

        const quartoInstalls = await getQuartoInstallations();
        this.combineVersionsWithInstalls(quartoInstalls, 'quartoVersions');

        const tensorflowInstalls = await getTensorFlowInstallations();
        this.combineVersionsWithInstalls(tensorflowInstalls, 'tensorflowVersions');

        // In the (rare) situation where R, Python, Quarto, and TensorFlow are all
        // disabled, build a fake (Local:Local) environment having no
        // installations.
        //
        // This lets us report (none) data to publishers.
        if (Object.keys(this.environments).length === 0) {
          this.environments['Local:Local'] = {
            clusterName: 'Local',
            imageName: 'Local',
            rVersions: [],
            pythonVersions: [],
            quartoVersions: [],
            tensorflowVersions: [],
          };
        }
      } catch (e) {
        this.setErrorMessageFromAPI(e);
      }
    },
  },
};
</script>
